/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";
import DOMPurify from "dompurify";

import { useStyles } from "../styles";

import { useStyles as sharedStyles } from "../../../../../shared";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

export type ListParagraphType = {
  bold: string;
  text: string;
};

interface ListParagraphProps {
  listItems: ListParagraphType[];
}

const ListParagraph = ({ listItems }: ListParagraphProps) => {
  const { colors } = useContext(ThemeContext);

  const { body2bold } = sharedStyles();
  const { paragrapghSpacement, paragraphListItem } = useStyles();

  return (
    <ul css={css(paragrapghSpacement)}>
      {listItems.map((item) => (
        <li key={item.bold} css={css(paragraphListItem)}>
          <Typography variant="body2">
            <span css={css(body2bold)}>{DOMPurify.sanitize(item.bold)} </span>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.text),
              }}
              css={css({
                a: {
                  color: colors.blue700,
                  textDecoration: "underline",
                },
              })}
            />
          </Typography>
        </li>
      ))}
    </ul>
  );
};

export default ListParagraph;
