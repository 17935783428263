import { CSSObject } from "@emotion/react";

import { NEWS_DIALOG_SIZES } from "./types/enums";

import { ThemeColors } from "../../../../shared";

export const opacityTransition = "opacity 0.5s ease-in-out";
const imageWidthToSm = "100%";
const imageWidthToLg = "49%";
const imageWidthFromLg = "32%";

const calculateSelectableImageWidth = (toSm?: boolean, toLg?: boolean) => {
  if (toSm) {
    return imageWidthToSm;
  }
  if (toLg) {
    return imageWidthToLg;
  }
  return imageWidthFromLg;
};

interface StyleProps {
  colors?: ThemeColors;
  listItem?: boolean;
  toMd?: boolean;
  toSm?: boolean;
  toLg?: boolean;
}

export const useStyles = ({ colors, listItem, toMd, toSm, toLg }: StyleProps) =>
  ({
    newsContentBox: {
      display: "flex",
      overflowX: "scroll",
      " -ms-overflow-style": "none" /* IE and Edge */,
      scrollbarWidth: "none" /* Firefox */,
    },
    emptyNews: {
      height: "92px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    newsCard: {
      maxWidth: "200px",
      minWidth: "200px",
      height: "172px",
      borderRadius: "6px",
      border: `1px solid ${colors?.gray100}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginRight: "16px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors?.gray50,
        transition: "background-color 0.3s ease-in-out",
      },
    },
    cardNoMargin: {
      marginRight: 0,
    },
    newsImageBox: {
      height: listItem ? "100px" : NEWS_DIALOG_SIZES.dialogImageHeight,
      paddingBottom: listItem ? " unset" : "24px",
    },
    textBox: {
      margin: "8px 16px 16px 16px",
      display: "flex",
      flexDirection: "column",
    },
    newsDialogTextContent: {
      height: toMd
        ? NEWS_DIALOG_SIZES.dialogTextContentLoaderHeightToMd
        : "107px",
      paddingBottom: "16px",
    },
    rightArrowButton: {
      padding: "4px 4px 4px 20px",
      marginLeft: "-44px",
      right: "-2px",
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",

      "&.Mui-focusVisible, &:hover, &:active, &:focus": {
        background:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },
    },
    leftArrowButton: {
      padding: "4px 20px 4px 0",
      marginRight: "-44px",
      left: "-2px",
      background:
        "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",

      "&.Mui-focusVisible, &:hover, &:active, &:focus": {
        background:
          "linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)",
      },
    },
    repeatedArrowButtonStyles: {
      width: "56px",
      height: "179px",
      justifyContent: "center",
      alignItems: "center",
      position: "sticky",
      top: "73px",
      zIndex: 30,
    },
    newsBadge: {
      zIndex: 20,
      position: "absolute",
      margin: "8px 0 0 8px",
    },
    newsDraftActions: {
      position: "absolute",
      top: "8px",
      right: "8px",
      zIndex: 20,
    },
    imageDropzone: {
      padding: "16px",
      borderRadius: "8px",
      display: "flex",
      border: `1px solid ${colors?.gray300}`,
      alignItems: "center",
      justifyContent: "space-between",
    },
    noImageWarning: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    selectableImageDiv: {
      width: calculateSelectableImageWidth(toSm, toLg),
      cursor: "pointer",
    },
    selectImageContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: toSm ? "center" : "flex-start",
      gap: "8px",
      width: "100%",
      scrollbarGutter: "stable both-edges",
    },
  } as CSSObject);
