/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import Tooltip from "../../Tooltip/Tooltip";

interface IconWithTooltipProps {
  icon: React.ReactNode;
  title: string | ReactNode;
  customIconStyle?: {};
  iconTestId?: string;
  tooltipMinWidth?: string;
}

const IconWithTooltip = ({
  icon,
  title,
  customIconStyle,
  iconTestId,
  tooltipMinWidth,
}: IconWithTooltipProps) => {
  return (
    <Tooltip
      title={title}
      size="medium"
      placement="top"
      hasArrow
      minWidth={tooltipMinWidth}
    >
      <span css={css([customIconStyle])} data-testid={iconTestId}>
        {icon}
      </span>
    </Tooltip>
  );
};

export default IconWithTooltip;
