import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface QuickDownloadColumnIconProps extends SvgIconProps {
  currentColor?: string;
}

const QuickDownloadColumnIcon = ({
  currentColor,
  ...props
}: QuickDownloadColumnIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F1F5F9" />
      <path
        d="M17.25 19.5H15C14.7875 19.5 14.6094 19.4281 14.4656 19.2844C14.3219 19.1406 14.25 18.9625 14.25 18.75C14.25 18.5375 14.3219 18.3594 14.4656 18.2156C14.6094 18.0719 14.7875 18 15 18H17.25V10.5H6.75V12.75C6.75 12.9625 6.67813 13.1406 6.53438 13.2844C6.39063 13.4281 6.2125 13.5 6 13.5C5.7875 13.5 5.60938 13.4281 5.46563 13.2844C5.32188 13.1406 5.25 12.9625 5.25 12.75V7.5C5.25 7.0875 5.39688 6.73438 5.69063 6.44063C5.98438 6.14688 6.3375 6 6.75 6H7.5V5.25C7.5 5.0375 7.57187 4.85938 7.71562 4.71562C7.85938 4.57187 8.0375 4.5 8.25 4.5C8.4625 4.5 8.64062 4.57187 8.78438 4.71562C8.92813 4.85938 9 5.0375 9 5.25V6H15V5.25C15 5.0375 15.0719 4.85938 15.2156 4.71562C15.3594 4.57187 15.5375 4.5 15.75 4.5C15.9625 4.5 16.1406 4.57187 16.2844 4.71562C16.4281 4.85938 16.5 5.0375 16.5 5.25V6H17.25C17.6625 6 18.0156 6.14688 18.3094 6.44063C18.6031 6.73438 18.75 7.0875 18.75 7.5V18C18.75 18.4125 18.6031 18.7656 18.3094 19.0594C18.0156 19.3531 17.6625 19.5 17.25 19.5Z"
        fill={currentColor || "#697D95"}
      />
      <path
        d="M5.6499 18.7375V15.519C5.6499 15.402 5.68891 15.3077 5.76694 15.2362C5.84496 15.1647 5.93599 15.1289 6.04002 15.1289C6.07253 15.1289 6.10829 15.1322 6.1473 15.1387C6.18631 15.1452 6.22207 15.1614 6.25458 15.1874L8.67331 16.8064C8.73182 16.8454 8.77571 16.8926 8.80497 16.9478C8.83423 17.0031 8.84886 17.0632 8.84886 17.1283C8.84886 17.1933 8.83423 17.2534 8.80497 17.3087C8.77571 17.3639 8.73182 17.4111 8.67331 17.4501L6.25458 19.0691C6.22207 19.0951 6.18631 19.1113 6.1473 19.1178C6.10829 19.1244 6.07253 19.1276 6.04002 19.1276C5.93599 19.1276 5.84496 19.0918 5.76694 19.0203C5.68891 18.9488 5.6499 18.8545 5.6499 18.7375ZM9.55107 18.7375V15.519C9.55107 15.402 9.59008 15.3077 9.6681 15.2362C9.74613 15.1647 9.83715 15.1289 9.94118 15.1289C9.97369 15.1289 10.0095 15.1322 10.0485 15.1387C10.0875 15.1452 10.1232 15.1614 10.1557 15.1874L12.5745 16.8064C12.633 16.8454 12.6769 16.8926 12.7061 16.9478C12.7354 17.0031 12.75 17.0632 12.75 17.1283C12.75 17.1933 12.7354 17.2534 12.7061 17.3087C12.6769 17.3639 12.633 17.4111 12.5745 17.4501L10.1557 19.0691C10.1232 19.0951 10.0875 19.1113 10.0485 19.1178C10.0095 19.1244 9.97369 19.1276 9.94118 19.1276C9.83715 19.1276 9.74613 19.0918 9.6681 19.0203C9.59008 18.9488 9.55107 18.8545 9.55107 18.7375Z"
        fill={currentColor || "#697D95"}
      />
    </SvgIcon>
  );
};

export default QuickDownloadColumnIcon;
