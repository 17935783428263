import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface MultipleScheduleColumnIconProps extends SvgIconProps {
  currentColor?: string;
}

const MultipleScheduleColumnIcon = ({
  currentColor,
  ...props
}: MultipleScheduleColumnIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F1F5F9" />
      <g>
        <mask x="4" y="4" width="16" height="16">
          <path d="M20 4H4V20H20V4Z" fill="white" />
        </mask>
        <g>
          <path
            d="M16.3101 8.44141H16.8101C17.6401 8.44141 18.3101 9.11141 18.3101 9.94141V17.4414C18.3101 18.2714 17.6401 18.9414 16.8101 18.9414H8.81006C7.98006 18.9414 7.31006 18.2714 7.31006 17.4414V16.4414"
            stroke={currentColor || "#697D95"}
            strokeWidth="1.4"
            fill="none"
          />
          <path
            d="M6.33 17.6208C5.96 17.6208 5.65 17.4908 5.39 17.2308C5.13 16.9708 5 16.6608 5 16.2908V6.96078C5 6.59078 5.13 6.28078 5.39 6.02078C5.65 5.76078 5.96 5.63078 6.33 5.63078H7V4.96078C7 4.77078 7.06 4.61078 7.19 4.49078C7.32 4.36078 7.48 4.30078 7.66 4.30078C7.84 4.30078 8.01 4.36078 8.13 4.49078C8.26 4.62078 8.32 4.78078 8.32 4.96078V5.63078H13.65V4.96078C13.65 4.77078 13.71 4.61078 13.84 4.49078C13.97 4.36078 14.13 4.30078 14.32 4.30078C14.51 4.30078 14.67 4.36078 14.8 4.49078C14.93 4.62078 14.99 4.78078 14.99 4.96078V5.63078H15.66C16.03 5.63078 16.34 5.76078 16.6 6.02078C16.86 6.28078 16.99 6.59078 16.99 6.96078V16.2908C16.99 16.6608 16.86 16.9708 16.6 17.2308C16.34 17.4908 16.03 17.6208 15.66 17.6208H6.33ZM6.33 16.2908H15.66V9.62078H6.33V16.2908Z"
            fill={currentColor || "#697D95"}
          />
          <path
            d="M12.57 15.2213H9.33996V14.5412L10.5 13.3712C10.84 13.0212 11.07 12.7713 11.17 12.6413C11.28 12.5013 11.35 12.3813 11.4 12.2613C11.45 12.1413 11.47 12.0212 11.47 11.9012C11.47 11.7112 11.42 11.5812 11.32 11.4912C11.22 11.4012 11.08 11.3512 10.91 11.3512C10.73 11.3512 10.56 11.3912 10.39 11.4712C10.22 11.5512 10.05 11.6712 9.85996 11.8212L9.32996 11.1912C9.55996 11.0012 9.74996 10.8612 9.89996 10.7812C10.05 10.7013 10.21 10.6412 10.39 10.6012C10.57 10.5612 10.77 10.5312 10.98 10.5312C11.27 10.5312 11.52 10.5812 11.74 10.6912C11.96 10.8012 12.13 10.9412 12.26 11.1312C12.38 11.3212 12.44 11.5412 12.44 11.7812C12.44 11.9913 12.4 12.1912 12.33 12.3812C12.26 12.5712 12.14 12.7612 11.98 12.9512C11.82 13.1512 11.55 13.4312 11.15 13.7912L10.56 14.3513V14.3913H12.57V15.2112V15.2213Z"
            fill={currentColor || "#697D95"}
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MultipleScheduleColumnIcon;
