/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import { Paper, Typography } from "@mui/material";
import { ReactNode, useContext } from "react";

import { useStyles } from "./styles";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface PaperBoxProps {
  children: ReactNode;
  customStyling?: any;
  boxTitle?: string;
  testid?: string;
  errorText?: string | null;
}

const PaperBox = ({
  children,
  customStyling,
  boxTitle,
  testid,
  errorText,
}: PaperBoxProps) => {
  const { colors } = useContext(ThemeContext);

  const { paperBox, boxTitleArea, errorIconStyles } = useStyles({ colors });

  return (
    <div data-testid={testid || "paper-box"} className="paper-box">
      {boxTitle && (
        <Typography variant="body2" sx={boxTitleArea as {}}>
          {boxTitle}
        </Typography>
      )}

      <Paper
        elevation={0}
        variant="outlined"
        sx={[paperBox as {}, customStyling]}
      >
        {children}
      </Paper>

      {errorText && (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            margin: "6px 0 0 0",
            justifyContent: "flex-start",
          }}
        >
          <ErrorIcon data-testid="error-icon" css={css(errorIconStyles)} />
          <Typography variant="body2">{errorText}</Typography>
        </div>
      )}
    </div>
  );
};

export default PaperBox;
