/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useContext } from "react";

import SingleCard from "../SingleCard/SingleCard";

import { CompanyCardDataDto, CompanyCardObjectDto } from "../../types";
import { useStyles } from "../../styles";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import Separator from "../../../../../../components/Separator/Separator";

interface CompanyCardListProps {
  cardData: CompanyCardDataDto;
  lastCard: boolean;
  scrolling?: boolean;
}

const CompanyCardList = ({
  cardData,
  lastCard,
  scrolling,
}: CompanyCardListProps) => {
  const { colors } = useContext(ThemeContext);

  const { companyCardsListBlock } = useStyles();

  const cardList = cardData.companyCards.map((card: CompanyCardObjectDto) => (
    <SingleCard key={card.id} card={card} scrolling={scrolling} />
  ));

  return (
    <div css={css(companyCardsListBlock)}>
      <div css={css({ padding: "8px 0" })}>
        <Typography
          variant="body3semiBold"
          sx={{ color: colors.textPlaceholder }}
        >
          {cardData.companyName}
        </Typography>
      </div>

      {cardList}
      {!lastCard && <Separator color={colors.gray200} />}
    </div>
  );
};

export default CompanyCardList;
