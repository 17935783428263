import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const NoSchedulesColumnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F1F5F9" />
      <g>
        <path
          d="M6.98 18.0004H17.02C18.0467 18.0004 18.6867 16.8871 18.1733 16.0004L13.1533 7.32711C12.64 6.44044 11.36 6.44044 10.8467 7.32711L5.82667 16.0004C5.31333 16.8871 5.95333 18.0004 6.98 18.0004ZM12 13.3338C11.6333 13.3338 11.3333 13.0338 11.3333 12.6671V11.3338C11.3333 10.9671 11.6333 10.6671 12 10.6671C12.3667 10.6671 12.6667 10.9671 12.6667 11.3338V12.6671C12.6667 13.0338 12.3667 13.3338 12 13.3338ZM12.6667 16.0004H11.3333V14.6671H12.6667V16.0004Z"
          fill="#EA580C"
        />
      </g>
      <defs>
        <clipPath id="clip0_8628_98698">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default NoSchedulesColumnIcon;
