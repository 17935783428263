import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

// if column changes might influence cached UI view, LOCAL_STORAGE_KEYS.ActiveTableColumns should be updated into new one.
// In this case deleteNotExistingLocalStorageKeys() method will be called in logout() function and eill remove old data from local storage
export const VEHICLES_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Schedules,
    isSticky: false,
    resource: COLUMN_RESOURCES.SchedulesList,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.VehicleNumber,
    isSticky: true,
    resource: COLUMN_RESOURCES.Number,
    sortResource: COLUMN_RESOURCES.Number,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.Imei,
    isSticky: false,
    resource: COLUMN_RESOURCES.Imei,
    isDefaultActive: true,
    isDisabled: true,
    width: "150px",
  },
  {
    name: COLUMN_NAMES.Status,
    isSticky: false,
    resource: COLUMN_RESOURCES.Status,
    isDefaultActive: true,
    isBadge: true,
  },
  {
    name: COLUMN_NAMES.LastConnection,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastConnected,
    sortResource: COLUMN_RESOURCES.LastConnected,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.CreatedBy,
    resource: COLUMN_RESOURCES.CreatedBy,
    sortResource: COLUMN_RESOURCES.CreatedBy,
  },
  {
    name: COLUMN_NAMES.CreatedAt,
    resource: COLUMN_RESOURCES.CreatedAt,
    sortResource: COLUMN_RESOURCES.CreatedAt,
  },
  {
    name: COLUMN_NAMES.UpdatedAt,
    resource: COLUMN_RESOURCES.UpdatedAt,
    sortResource: COLUMN_RESOURCES.UpdatedAt,
  },
];
