import { useState, ReactNode, createContext, useMemo } from "react";

import moment from "moment-timezone";
import { LOCAL_STORAGE_KEYS } from "../../shared";

export const TimeZoneContext = createContext<any>(null);

interface TimeZoneProviderProps {
  children: ReactNode;
}

const TimeZoneProvider = ({ children }: TimeZoneProviderProps) => {
  const getSystemTimezone = (): string => moment.tz.guess(true);

  const initialTimeZone = () => {
    const names = moment.tz.names();
    const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEYS.TimeZone);
    const detectedTimeZone = getSystemTimezone();

    return localStorageValue && names.includes(localStorageValue)
      ? localStorageValue
      : detectedTimeZone;
  };

  const [currentTimeZone, setCurrentTimeZone] = useState(initialTimeZone());

  const changeTimeZone = (timeZone: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TimeZone, timeZone);
    setCurrentTimeZone(timeZone);
  };

  const revertToSystemTimeZone = () => {
    setCurrentTimeZone(getSystemTimezone());
  };

  const timeZoneContextProviderValue = useMemo(() => {
    return {
      currentTimeZone,
      systemTimeZone: getSystemTimezone(),
      changeTimeZone,
      getSystemTimezone,
      revertToSystemTimeZone,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeZone]);

  return (
    <TimeZoneContext.Provider value={timeZoneContextProviderValue}>
      {children}
    </TimeZoneContext.Provider>
  );
};

export default TimeZoneProvider;
