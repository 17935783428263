import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface SingleScheduleColumnIconProps extends SvgIconProps {
  currentColor?: string;
}

const SingleScheduleColumnIcon = ({
  currentColor,
  ...props
}: SingleScheduleColumnIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00195312" width="24" height="24" rx="4" fill="#F1F5F9" />
      <g clipPath="url(#clip0_8622_123789)">
        <path
          d="M6.81498 19.4086C6.40757 19.4086 6.0588 19.2635 5.76868 18.9734C5.47856 18.6833 5.3335 18.3345 5.3335 17.9271V7.55671C5.3335 7.14931 5.47856 6.80054 5.76868 6.51042C6.0588 6.22029 6.40757 6.07523 6.81498 6.07523H7.55572V5.33449C7.55572 5.12461 7.62671 4.94869 7.76868 4.80671C7.91066 4.66474 8.08658 4.59375 8.29646 4.59375C8.50634 4.59375 8.68226 4.66474 8.82424 4.80671C8.96621 4.94869 9.0372 5.12461 9.0372 5.33449V6.07523H14.9631V5.33449C14.9631 5.12461 15.0341 4.94869 15.1761 4.80671C15.3181 4.66474 15.494 4.59375 15.7039 4.59375C15.9137 4.59375 16.0897 4.66474 16.2316 4.80671C16.3736 4.94869 16.4446 5.12461 16.4446 5.33449V6.07523H17.1853C17.5928 6.07523 17.9415 6.22029 18.2316 6.51042C18.5218 6.80054 18.6668 7.14931 18.6668 7.55671V17.9271C18.6668 18.3345 18.5218 18.6833 18.2316 18.9734C17.9415 19.2635 17.5928 19.4086 17.1853 19.4086H6.81498ZM6.81498 17.9271H17.1853V10.5197H6.81498V17.9271Z"
          fill={currentColor || "#697D95"}
        />
        <path
          d="M12.7611 16.2616H11.7845V13.5876L11.794 13.1483L11.8098 12.6679C11.6475 12.8301 11.5348 12.9365 11.4716 12.9871L10.9406 13.4138L10.4696 12.8259L11.9583 11.6406H12.7611V16.2616Z"
          fill={currentColor || "#697D95"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8622_123789">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4.00195)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SingleScheduleColumnIcon;
