import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ResourceParams } from "../../types";
import Dialog from "../../../Dialog/Dialog";
import { DialogWidth } from "../../../Dialog/types/enums";
import {
  DRIVERS_ACTIONS,
  GeneralDialogProps,
  VEHICLES_ACTIONS,
  useMediaQueries,
} from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";
import useResponse from "../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../api/hooks/useAxiosPrivate";

interface ConfirmDeleteDialogProps extends GeneralDialogProps {
  resourceParams: ResourceParams;
  scheduleId: string;
  scheduleName: string;
  handleContentRefresh: (
    dialogName: VEHICLES_ACTIONS | DRIVERS_ACTIONS,
    successMessage: string,
    leaveRowsChecked?: boolean
  ) => Promise<void>;
}

const ConfirmDeleteDialog = ({
  resourceParams,
  scheduleId,
  scheduleName,
  isOpen,
  onClose,
  handleContentRefresh,
}: ConfirmDeleteDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { deleteData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const [isLoading, setIsLoading] = useState(false);

  const { resourceName, actionDelete, apiResource } = resourceParams;

  const handleCloseDialog = () => onClose(actionDelete);

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      await deleteData(apiResource, scheduleId);

      await handleContentRefresh(
        actionDelete,
        t("Alert##successfully deleted", {
          resourceString: t("General##schedule"),
        }),
        true
      );
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      data-testid="confirm-delete-schedule-dialog"
      title={t(`Schedules##confirm delete##title`)}
      description={t(
        `Schedules##confirm delete##description##${resourceName}`,
        {
          scheduleName,
        }
      )}
      open={isOpen}
      isTitleSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={handleCloseDialog}
      submit={handleDelete}
      actions={
        <DialogActions
          onClose={handleCloseDialog}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##delete")}
          confirmationButtonTestId="confirm-delete-schedule-confirmation-button"
          cancelButtonText={t("Button##cancel")}
          cancelButtonTestId="confirm-delete-schedule-cancel-button"
        />
      }
    />
  );
};

export default ConfirmDeleteDialog;
