import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

interface DialogParagraphProps {
  text: string;
}

const DialogParagraph = ({ text }: DialogParagraphProps) => {
  return (
    <Typography
      variant="body2"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} // this type of code allows us to escape HTML tags.
      sx={{
        marginBottom: "16px",
      }}
    />
  );
};

export default DialogParagraph;
