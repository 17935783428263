import { useContext, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { usePapaParse } from "react-papaparse";

import useSingleFileFunctions from "./useSingleFileFunctions";
import UploadedFileSingle from "./UploadedFileSingle";

import Frame from "../Frame";
import { DataProps, SingleFileProps } from "../types";

import { BANNER_MESSAGE_STATUSES } from "../../BannerMessage/types";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import { Message } from "../../../context/dropzone/dropzoneReducer";

const SingleFile = ({
  supportedFormats,
  isDisabled,
  maxFileNameLength,
  countCsv,
  tooltipText,
  setErrorbannerMessage,
}: SingleFileProps) => {
  const { file, setFile, setMessage } = useContext(DropzoneContext);

  const { t } = useTranslation();
  const { readString } = usePapaParse();

  const {
    checkifExceedsRowLimit,
    checkIfCorrectNameLength,
    getErrors,
  } = useSingleFileFunctions();

  const [isDragged, setIsDragged] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/html": supportedFormats },
    disabled: isDisabled,
    multiple: false,
    onDragEnter: () => setIsDragged(true),
    onDragLeave: () => setIsDragged(false),
    onDrop: () => {
      setIsDragged(false);
    },
    onDropAccepted: (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];

      const isCorrectNameLength = checkIfCorrectNameLength(
        maxFileNameLength,
        acceptedFile
      );

      if (countCsv) {
        const reader = new FileReader();

        reader.readAsText(acceptedFile);

        reader.onload = () => {
          readString(reader.result as string, {
            worker: true,
            delimiter: ",",
            skipEmptyLines: true,
            complete: ({ data }: DataProps) => {
              isCorrectNameLength &&
                !checkifExceedsRowLimit(data.length, setMessage) &&
                setFile(acceptedFile, data);
            },
          });
        };
      } else {
        isCorrectNameLength && setFile(acceptedFile);
      }

      !isCorrectNameLength &&
        setMessage(
          {
            status: BANNER_MESSAGE_STATUSES.Critical,
            title: t("Dropzone##too long file name", {
              maxLength: maxFileNameLength,
            }),
          },
          true
        );
    },
    onDropRejected: (rejected) => {
      const errorCodes = rejected.reduce((previous: string[], current) => {
        const currentErrors = current.errors.map((error) => error.code);

        return [...previous, ...currentErrors];
      }, []);

      const uniqueErrorCodes = _.uniq(errorCodes);

      const errors: Message[] = getErrors(uniqueErrorCodes);

      setMessage(errors, true);
    },
  });

  return file ? (
    <UploadedFileSingle
      countCsv={countCsv}
      setErrorbannerMessage={setErrorbannerMessage}
    />
  ) : (
    <Frame
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragged={isDragged}
      supportedFormats={supportedFormats}
      isDisabled={isDisabled}
      tooltipText={tooltipText}
    />
  );
};

export default SingleFile;
