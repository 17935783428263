import { useState } from "react";

const useIsScrolling = () => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  let scrollTimer = -1;

  const handleScroll = () => {
    setIsScrolling(true);

    if (scrollTimer !== -1) clearTimeout(scrollTimer);

    scrollTimer = window.setTimeout(scrollFinished, 500);
  };

  const scrollFinished = () => {
    setIsScrolling(false);
  };

  return { handleScroll, isScrolling };
};

export default useIsScrolling;
