/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useContext } from "react";
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";
import { Typography } from "@mui/material";

import { useStyles } from "./styles";

import { useStyles as sharedStyles } from "../../shared/styles/sharedStyles";
import CheckboxDefaultIcon from "../../assets/customIcons/checkbox/CheckboxDefaultIcon";
import CheckboxDefaultCheckedIcon from "../../assets/customIcons/checkbox/CheckboxDefaultCheckedIcon";
import CheckboxDefaultIndeterminateIcon from "../../assets/customIcons/checkbox/CheckboxDefaultIndeterminateIcon";
import CheckboxDisabledIcon from "../../assets/customIcons/checkbox/CheckboxDisabledIcon";
import CheckboxDisabledCheckedIcon from "../../assets/customIcons/checkbox/CheckboxDisabledCheckedIcon";
import CheckboxDisabledIndeterminateIcon from "../../assets/customIcons/checkbox/CheckboxDisabledIndeterminateIcon";
import CheckboxErrorIcon from "../../assets/customIcons/checkbox/CheckboxErrorIcon";
import CheckboxErrorCheckedIcon from "../../assets/customIcons/checkbox/CheckboxErrorCheckedIcon";
import CheckboxErrorIndeterminateIcon from "../../assets/customIcons/checkbox/CheckboxErrorIndeterminateIcon";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

export interface ICheckboxProps {
  label?: string | ReactNode;
  customLabel?: string | ReactNode;
  info?: string;
  isError?: boolean;
  customStyle?: {};
  customId?: string;
}

const Checkbox = ({
  label,
  customLabel,
  info,
  isError,
  customStyle,
  customId,
  ...props
}: ICheckboxProps & MuiCheckboxProps) => {
  const {
    colors: {
      blue700,
      blue800,
      gray300,
      gray400,
      gray700,
      textDark,
      textDarkDisabled,
    },
  } = useContext(ThemeContext);

  const { checkboxContent, checkboxLabel } = useStyles();
  const { body3regular } = sharedStyles();

  const getIcons = () => {
    if (props.disabled) {
      return {
        icon: <CheckboxDisabledIcon />,
        checkedIcon: <CheckboxDisabledCheckedIcon />,
        indeterminateIcon: <CheckboxDisabledIndeterminateIcon />,
      };
    } else if (isError) {
      return {
        icon: <CheckboxErrorIcon />,
        checkedIcon: <CheckboxErrorCheckedIcon />,
        indeterminateIcon: <CheckboxErrorIndeterminateIcon />,
      };
    } else {
      return {
        icon: <CheckboxDefaultIcon />,
        checkedIcon: <CheckboxDefaultCheckedIcon />,
        indeterminateIcon: <CheckboxDefaultIndeterminateIcon />,
      };
    }
  };

  const icon = getIcons().icon;
  const checkedIcon = getIcons().checkedIcon;
  const indeterminateIcon = getIcons().indeterminateIcon;

  const isDefault = !props.disabled && !isError;

  const getHoverColor = () => {
    if (!isDefault) {
      return blue700;
    }

    return props.indeterminate ? blue800 : gray400;
  };

  const hoverColor = getHoverColor();

  return (
    <label
      css={css([
        checkboxLabel,
        {
          ...customStyle,
        },
      ])}
    >
      <MuiCheckbox
        data-testid={`checkbox${customId}`}
        {...props}
        disableRipple
        size="small"
        icon={icon}
        checkedIcon={checkedIcon}
        indeterminateIcon={indeterminateIcon}
        sx={{
          alignSelf: "flex-start",
          padding: "0",
          color: gray300,
          transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&.Mui-checked, &.MuiCheckbox-indeterminate": {
            color: blue700,
          },

          "& > svg": {
            fontSize: "24px",
          },

          "&:hover": {
            color: hoverColor,

            "&.Mui-checked": {
              color: isDefault ? blue800 : blue700,
            },
          },
        }}
      />

      <div
        css={css([
          {
            cursor: props.disabled ? "auto" : "pointer",
            marginLeft: label || info || customLabel ? "8px" : "0",
          },
          checkboxContent,
        ])}
      >
        {label && (
          <Typography
            variant="body2"
            sx={{ color: props.disabled ? textDarkDisabled : textDark }}
          >
            {label}
          </Typography>
        )}

        {customLabel && <>{customLabel}</>}

        {info && (
          <Typography
            data-testid="checkbox-info"
            css={css([
              {
                color: props.disabled ? textDarkDisabled : gray700,
                marginTop: label ? "0" : "2px",
              },
              body3regular,
            ])}
          >
            {info}
          </Typography>
        )}
      </div>
    </label>
  );
};

export default Checkbox;
