import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../shared";

interface UseStylesProps {
  colors?: ThemeColors;
}

export const useStyles = ({ colors }: UseStylesProps) =>
  ({
    paperBox: {
      borderRadius: "8px",
      padding: "16px",
      border: `1px solid ${colors?.gray300}`,
      color: colors?.textSecondary,
    },
    boxTitleArea: {
      marginBottom: "10px",
      color: colors?.textSecondary,
    },
    errorIconStyles: {
      color: colors?.red700,
      fontSize: "16px",
      margin: "2px 4px 0 0",
      alignSelf: "flex-start",
    },
  } as CSSObject);
