/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ReactQuill from "react-quill-new";
import { useContext, useState } from "react";

// since the package does not have .map files and throws warning:
// Failed to parse source map from '/Users/laurapociuniene/projects/work/tacho-front-end/node_modules/react-quill-new/dist/quill.snow.css.map'
//  css file is customised and imported directly
import "./styles/customReactQuillNewStyle.css";
import { useStyles } from "./styles/styles";

import PaperBox from "../PaperBox/PaperBox";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface RichTextFieldProps {
  label: string;
  isRichTextFocused: boolean;
  richTextInputError: string | null;
  richTextValue: string;
  handleChangeRichTextValue: (value: string) => void;
  validateRichTextLength: (value: any, fromOnBlur?: boolean) => void;
  handleFocus: () => void;
  testId?: string;
  edit?: boolean;
}

const RichTextField = ({
  label,
  isRichTextFocused,
  richTextInputError,
  richTextValue,
  handleChangeRichTextValue,
  validateRichTextLength,
  handleFocus,
  testId,
  edit = false,
}: RichTextFieldProps) => {
  const { colors } = useContext(ThemeContext);

  const [preventOnChange, setPreventOnChange] = useState(edit);

  const { richTextFieldContainer } = useStyles({
    colors,
    isRichTextFocused,
    richTextInputError,
  });

  const handleKeyDown = () => {
    setPreventOnChange(false);
  };

  return (
    <PaperBox
      testid={testId || "rich-text-field"}
      boxTitle={label}
      customStyling={richTextFieldContainer}
      errorText={richTextInputError}
    >
      <ReactQuill
        theme="snow"
        value={richTextValue}
        onChange={(value: string) =>
          !preventOnChange && handleChangeRichTextValue(value)
        }
        onBlur={() => validateRichTextLength(richTextValue, true)}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        bounds={".paper-box"} // bounds to prevent quill toolbar from going outside the box
        // override styles does not work in this case when moved to separate file
        css={css({
          height: "110px",
          ".ql-toolbar.ql-snow": {
            border: "none",
            ".ql-clean": { display: "none" },
          },
          ".ql-container.ql-snow": { border: "none" },
        })}
      />
    </PaperBox>
  );
};

export default RichTextField;
