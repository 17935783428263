import { CompanyCardObjectDto } from "../../types";
import useCardStatusFunctions from "../../hooks/useCardStatusFunctions";

import SharedStatusBadge from "../../../../../../components/SharedStatusBadge/SharedStatusBadge";
import { COMPANY_CARD_STATUS } from "../../../../../../shared";
import { TooltipSizes } from "../../../../../../components/Tooltip/types";

interface CardStatusBadgeProps {
  card: CompanyCardObjectDto;
  tooltipSize?: TooltipSizes;
  scrolling?: boolean;
}

const CardStatusBadge = ({
  card,
  tooltipSize,
  scrolling,
}: CardStatusBadgeProps) => {
  const {
    disconnectedStatusText,
    warningBadgeIcon,
    expiredBadgeIcon,
    expiredStatusText,
    checkIfExpirationDayIsInThreeMonths,
    connectedStatusInExpireTooltipText,
  } = useCardStatusFunctions({ card });

  const renderIconLeft = () => {
    switch (card.status) {
      // show warning icon only if expiration date is in three months
      case COMPANY_CARD_STATUS.Disconnected:
      case COMPANY_CARD_STATUS.Connected:
        return warningBadgeIcon(card.status);
      case COMPANY_CARD_STATUS.Expired:
        return expiredBadgeIcon;

      default:
        return undefined;
    }
  };

  const renderCustomTooltipText = () => {
    switch (card.status) {
      case COMPANY_CARD_STATUS.Disconnected:
        return disconnectedStatusText;
      case COMPANY_CARD_STATUS.Expired:
        return expiredStatusText;
      case COMPANY_CARD_STATUS.Connected:
        return checkIfExpirationDayIsInThreeMonths()
          ? connectedStatusInExpireTooltipText
          : undefined;
      default:
        return undefined;
    }
  };

  return (
    <SharedStatusBadge
      status={card.status as COMPANY_CARD_STATUS}
      iconLeft={renderIconLeft()}
      customTooltipText={renderCustomTooltipText()}
      tooltipSize={tooltipSize}
      showTooltip={!scrolling}
    />
  );
};

export default CardStatusBadge;
