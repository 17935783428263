import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface GlobalScheduleColumnIconProps extends SvgIconProps {
  currentColor?: string;
}

const GlobalScheduleColumnIcon = ({
  currentColor,
  ...props
}: GlobalScheduleColumnIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F1F5F9" />
      <path
        d="M12 18.6654C11.0778 18.6654 10.2112 18.4904 9.40004 18.1404C8.58893 17.7904 7.88337 17.3154 7.28337 16.7154C6.68337 16.1154 6.20837 15.4098 5.85837 14.5987C5.50837 13.7876 5.33337 12.9209 5.33337 11.9987C5.33337 11.0765 5.50837 10.2098 5.85837 9.3987C6.20837 8.58759 6.68337 7.88203 7.28337 7.28203C7.88337 6.68203 8.58893 6.20703 9.40004 5.85703C10.2112 5.50703 11.0778 5.33203 12 5.33203C12.9223 5.33203 13.7889 5.50703 14.6 5.85703C15.4112 6.20703 16.1167 6.68203 16.7167 7.28203C17.3167 7.88203 17.7917 8.58759 18.1417 9.3987C18.4917 10.2098 18.6667 11.0765 18.6667 11.9987C18.6667 12.9209 18.4917 13.7876 18.1417 14.5987C17.7917 15.4098 17.3167 16.1154 16.7167 16.7154C16.1167 17.3154 15.4112 17.7904 14.6 18.1404C13.7889 18.4904 12.9223 18.6654 12 18.6654ZM11.3334 17.2987V15.9987C10.9667 15.9987 10.6528 15.8681 10.3917 15.607C10.1306 15.3459 10 15.032 10 14.6654V13.9987L6.80004 10.7987C6.76671 10.9987 6.73615 11.1987 6.70837 11.3987C6.6806 11.5987 6.66671 11.7987 6.66671 11.9987C6.66671 13.3431 7.10837 14.5209 7.99171 15.532C8.87504 16.5431 9.98893 17.132 11.3334 17.2987ZM15.9334 15.5987C16.1556 15.3543 16.3556 15.0904 16.5334 14.807C16.7112 14.5237 16.8584 14.2293 16.975 13.9237C17.0917 13.6181 17.1806 13.3043 17.2417 12.982C17.3028 12.6598 17.3334 12.332 17.3334 11.9987C17.3334 10.9098 17.0306 9.91536 16.425 9.01536C15.8195 8.11536 15.0112 7.46536 14 7.06536V7.33203C14 7.6987 13.8695 8.01259 13.6084 8.2737C13.3473 8.53481 13.0334 8.66536 12.6667 8.66536H11.3334V9.9987C11.3334 10.1876 11.2695 10.3459 11.1417 10.4737C11.0139 10.6015 10.8556 10.6654 10.6667 10.6654H9.33337V11.9987H13.3334C13.5223 11.9987 13.6806 12.0626 13.8084 12.1904C13.9362 12.3181 14 12.4765 14 12.6654V14.6654H14.6667C14.9556 14.6654 15.2167 14.7515 15.45 14.9237C15.6834 15.0959 15.8445 15.3209 15.9334 15.5987Z"
        fill={currentColor || "#697D95"}
      />
    </SvgIcon>
  );
};

export default GlobalScheduleColumnIcon;
