import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../../shared";

interface RichTextFieldStyleProps {
  colors: ThemeColors;
  isRichTextFocused: boolean;
  richTextInputError: string | null;
}

export const useStyles = ({
  colors,
  isRichTextFocused,
  richTextInputError,
}: RichTextFieldStyleProps) => {
  const boxShadow =
    isRichTextFocused &&
    `-2px -2px 0px ${colors.blue200}, -2px 2px 0px ${colors.blue200}, 2px -2px 0px ${colors.blue200}, 2px 2px 0px ${colors.blue200}`;

  const focusedFieldBorderColor = isRichTextFocused && colors.blue700;

  const focusedWithErrorFieldBorderColor =
    isRichTextFocused && richTextInputError && colors.red700;

  const withErrorFieldBorderColor = richTextInputError && colors.red600;

  return {
    richTextFieldContainer: {
      height: "152px",
      padding: "0",
      borderColor:
        focusedFieldBorderColor ||
        focusedWithErrorFieldBorderColor ||
        withErrorFieldBorderColor,
      boxShadow,

      "&:hover": {
        borderColor: richTextInputError
          ? colors.red800
          : !isRichTextFocused && colors.gray400,
      },
    },
  } as CSSObject;
};
