/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SyntheticEvent, useContext } from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useQueryDropdown from "./hooks/useQueryDropdown";
import Checkbox from "../CheckBox/Checkbox";
import Tag from "../Tag/Tag";
import { TAG_COLORS, TAG_SIZES } from "../Tag/enums";
import Autocomplete, { AutocompleteProps } from "../Autocomplete/Autocomplete";
import { ID_TYPE, LOADER_SIZES, Resource } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import CursorImage from "../../assets/customIcons/cursors/cursor-not-allowed.png";

interface QueryDropdownProps {
  resource: string;
  idType: ID_TYPE;
  change: (e: SyntheticEvent, value: any) => void;
  descriptionResource?: Resource;
  textFieldParams?: {};
  exceptions?: number | number[];
  hasNotAssigned?: boolean;
  isDisabled?: boolean;
  noCheckboxes?: boolean;
  testId?: string;
  showSupportRole?: boolean;
}

const QueryDropdown = ({
  resource,
  idType,
  change,
  descriptionResource,
  textFieldParams,
  exceptions,
  hasNotAssigned,
  isDisabled,
  noCheckboxes,
  testId,
  showSupportRole,
  ...props
}: QueryDropdownProps &
  AutocompleteProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size" | "options"
  >) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { isLoading, options } = useQueryDropdown(resource, showSupportRole);

  const circularProgressSize = props.size === LOADER_SIZES.Tiny ? 12 : 16;
  const cursorStyle = isDisabled ? `url(${CursorImage}), default` : "default";

  const getOptionLabel = (name: string) => {
    return name === "not assigned" ? t("General##not assigned") : name;
  };

  const handleChange = (e: SyntheticEvent, option: any) => {
    change(e, option);
  };

  return (
    <>
      <Autocomplete
        {...props}
        data-testid={testId || "query-dropdown-autocomplete"}
        disabled={isDisabled}
        options={options}
        value={props.value}
        disableCloseOnSelect={props.multiple}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => getOptionLabel(option.name)}
        renderOption={(propsData, { id, name, description }, state) => {
          return (
            <li {...propsData} key={id}>
              <div
                css={css({
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                <span>{getOptionLabel(name)}</span>

                {description && (
                  <Typography
                    variant="subtitle1"
                    sx={{ color: colors.gray700 }}
                  >
                    {description}
                  </Typography>
                )}
              </div>

              <div
                css={css({
                  marginLeft: "auto",
                  alignSelf: "flex-start",
                })}
              >
                {props.multiple && <Checkbox checked={state.selected} />}
              </div>
            </li>
          );
        }}
        loading={isLoading}
        onChange={(event, option) => handleChange(event, option)}
        textFieldParams={textFieldParams ? textFieldParams : {}}
        iconRight={
          isLoading ? <CircularProgress size={circularProgressSize} /> : null
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Tag
              {...getTagProps({ index })}
              key={option.id}
              size={TAG_SIZES.Small}
              color={TAG_COLORS.Primary}
              title={option?.name}
            />
          ))
        }
        css={css({
          "& .MuiAutocomplete-inputRoot": {
            gap: "3px",
            cursor: cursorStyle,

            "& .Mui-disabled": {
              cursor: cursorStyle,
            },
          },

          "& .MuiAutocomplete-tag": {
            margin: "0",
          },
        })}
      />
    </>
  );
};

export default QueryDropdown;
