import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

// if column changes might influence cached UI view, LOCAL_STORAGE_KEYS.ActiveTableColumns should be updated into new one.
// In this case deleteNotExistingLocalStorageKeys() method will be called in logout() function and eill remove old data from local storage
export const DRIVERS_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Schedules,
    isSticky: false,
    resource: COLUMN_RESOURCES.SchedulesList,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.Driver,
    isSticky: true,
    resource: COLUMN_RESOURCES.CardName,
    sortResource: COLUMN_RESOURCES.CardName,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.CardNumber,
    isSticky: false,
    resource: COLUMN_RESOURCES.CardNumber,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.LastActivity,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastActivity,
    sortResource: COLUMN_RESOURCES.LastActivity,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.LastConnection,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastConnected,
    sortResource: COLUMN_RESOURCES.LastConnected,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.CreatedBy,
    resource: COLUMN_RESOURCES.CreatedBy,
    sortResource: COLUMN_RESOURCES.CreatedBy,
  },
  {
    name: COLUMN_NAMES.CreatedAt,
    resource: COLUMN_RESOURCES.CreatedAt,
    sortResource: COLUMN_RESOURCES.CreatedAt,
  },
  {
    name: COLUMN_NAMES.UpdatedAt,
    resource: COLUMN_RESOURCES.UpdatedAt,
    sortResource: COLUMN_RESOURCES.UpdatedAt, // BE is not implemented yet
  },
];
