/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useRef } from "react";

import { opacityTransition, useStyles } from "../styles";

import Button from "../../../../../components/Button/Button";

interface ArrowButtonProps {
  scroll: (scrollOffset: number) => void;
  customStyles?: any;
  endOfScroll: boolean;
  icon: React.ReactNode;
  scrollOffset: number;
  testId?: string;
}

const ArrowButton = ({
  scroll,
  customStyles,
  endOfScroll,
  icon,
  scrollOffset,
  testId,
}: ArrowButtonProps) => {
  const { repeatedArrowButtonStyles } = useStyles({});

  const intervalRef = useRef<any>(null);

  const handleMouseDown = () => {
    // Start scrolling when the button is pressed
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        scroll(scrollOffset);
      }, 0);
    }
  };

  const handleMouseUp = () => {
    // Clear the interval when the button is released
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <Button
      variant="iconOnly"
      size="small"
      color="secondary"
      data-testid={testId}
      disableRipple
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      css={css([
        customStyles,
        repeatedArrowButtonStyles,
        {
          opacity: endOfScroll ? 0 : 1,
          transition: opacityTransition,
        },
      ])}
      icon={icon}
    />
  );
};

export default ArrowButton;
