import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { DRIVERS_TABLE_COLUMNS } from "./config";

import PageWrapperWithDetails from "../wrappers/PageWrapperWithDetails";
import Table from "../../components/Table/Table";
import { ROUTES, TABLE_NAMES } from "../../shared";
import LayoutWrapper from "../../components/Layout/LayoutWrapper";
import useTableData from "../../components/Table/hooks/useTableData";
import DriversHeaderActions from "../../components/PageHeader/actions/pageHeaderActions/DriversHeaderActions/DriversHeaderActions";
import useDriverInlineActions from "../../components/Table/actions/inlineActions/hooks/useDriverInlineActions";
import EditDriverDialog from "../../components/Table/actions/dialogs/Drivers/EditDriverDialog/EditDriverDialog";
import { useAuth } from "../../context/Auth/AuthProvider";
import { isTableCheckable } from "../../atoms/atoms";
import { USER_PERMISSIONS } from "../../roles/types/enums";

export const mapDriversTableRowData = (data: any) =>
  data.map((dataItem: any) => {
    const schedules = dataItem.scheduleSummaries?.map(
      (schedule: { name: string; scope: number }) => schedule.name
    );

    return {
      ...dataItem,
      schedulesList: schedules,
    };
  });

const Drivers = () => {
  const { getAccessStatus } = useAuth();

  useTableData(mapDriversTableRowData);
  const {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  } = useDriverInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  const allowedToManageDrivers = getAccessStatus(
    USER_PERMISSIONS.ManageDrivers
  );

  useEffect(() => {
    setIsCheckable(allowedToManageDrivers);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWrapperWithDetails
        pathname={ROUTES.Drivers}
        currTableColumns={DRIVERS_TABLE_COLUMNS}
        currTableName={TABLE_NAMES.Drivers}
        inlineActions={allowedToManageDrivers ? inlineActions : []}
      >
        <LayoutWrapper showDetailsSection>
          {allowedToManageDrivers ? <DriversHeaderActions /> : <></>}
        </LayoutWrapper>
        <Table hasDetails hasTableView />
      </PageWrapperWithDetails>

      {/* inline actions dialog */}
      {rowData && (
        <EditDriverDialog
          isOpen={isOpen}
          onClose={closeDialog}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default Drivers;
