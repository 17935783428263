/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

import { useStyles } from "../styles";
import { NewsDto } from "../types";

import { ThemeColors, useDateTime } from "../../../../../shared";

interface NewsDialogTextContentProps {
  colors: ThemeColors;
  newsData: NewsDto | null;
  toMd: boolean;
}

const NewsDialogTextContent = ({
  colors,
  newsData,
  toMd,
}: NewsDialogTextContentProps) => {
  const { formatDateToEnString } = useDateTime();
  const { newsDialogTextContent } = useStyles({ colors, toMd });

  return (
    <div
      css={css(newsDialogTextContent)}
      data-testid="news-dialog-text-content"
    >
      <Typography variant="body3semiBold" color={colors.textPlaceholder}>
        {formatDateToEnString(newsData?.createdAt)}
      </Typography>

      {newsData?.content && (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(newsData?.content), // this type of code allows to escape HTML tags.
          }}
          sx={{
            'li[data-list="bullet"]': { listStyleType: "disc" },
            li: { listStyleType: "inherit" },
            a: { color: colors.blue600, textDecoration: "underline" },
          }}
        />
      )}
      {/* the div below is added to make some space at the bottom of the long text */}
      <div css={css({ height: "16px" })}></div>
    </div>
  );
};

export default NewsDialogTextContent;
