import { useCallback, useRef, useState } from "react";

const useNewsFunctions = () => {
  const [endOfScrollForward, setEndOfScrollForward] = useState<boolean>(false);
  const [endOfScrollBackward, setEndOfScrollBackward] = useState<boolean>(true);

  const lastElObserver = useRef<any>(null);
  const firstElObserver = useRef<any>(null);

  const lastListElementRef = useCallback(
    (node: any) => {
      if (!node) return;
      if (lastElObserver.current) lastElObserver.current.disconnect();
      lastElObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setEndOfScrollForward(true);
        } else {
          setEndOfScrollForward(false);
        }
      });
      if (node) lastElObserver.current.observe(node);
    },

    [setEndOfScrollForward]
  );

  const firstListElementRef = useCallback(
    (node: any) => {
      if (!node) return;
      if (firstElObserver.current) firstElObserver.current.disconnect();
      firstElObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setEndOfScrollBackward(true);
        } else {
          setEndOfScrollBackward(false);
        }
      });
      if (node) firstElObserver.current.observe(node);
    },

    [setEndOfScrollBackward]
  );

  return {
    lastElObserver,
    lastListElementRef,
    endOfScrollForward,
    firstElObserver,
    endOfScrollBackward,
    firstListElementRef,
    setEndOfScrollForward,
    setEndOfScrollBackward,
  };
};

export default useNewsFunctions;
