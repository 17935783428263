/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import useCompanyCards from "./hooks/useCompanyCards";
import { CompanyCardDataDto } from "./types";
import { useStyles } from "./styles";
import CompanyCardList from "./components/CompanyCardList/CompanyCardList";

import CardWrapper from "../../CardWrapper/CardWrapper";
import NoResultsCard from "../../NoResultsCard/NoResultsCard";

import { companyCardsState, tableLoading } from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import useIsScrolling from "../../../../shared/hooks/useIsScrolling";

const CompanyCards = () => {
  const { isLoading } = useCompanyCards();
  const { handleScroll, isScrolling } = useIsScrolling();
  const { t } = useTranslation();

  const isTableLoading = useRecoilValue(tableLoading);
  const companyCardsData = useRecoilValue(companyCardsState);

  const { companyCardContainer } = useStyles();

  const loading = isTableLoading || isLoading;
  const isEmpty = companyCardsData?.length === 0;

  const generateCompanyCardsList = () =>
    companyCardsData?.map((card: CompanyCardDataDto, index: number) => (
      <CompanyCardList
        key={card.companyName}
        cardData={card}
        lastCard={index === companyCardsData.length - 1}
        scrolling={isScrolling}
      />
    ));

  const renderCardData = () => {
    if (companyCardsData) {
      return isEmpty ? <NoResultsCard /> : generateCompanyCardsList();
    }
    return <></>;
  };

  return (
    <CardWrapper
      isTopSection
      cardTitle={t("Dashboard##company cards")}
      isCompanyCards
      isLoading={loading}
    >
      <div css={css(companyCardContainer)} onScroll={handleScroll}>
        {loading ? (
          <RectangleLoader
            width={"100%"}
            height={"100%"}
            testId="company-cards-loader"
          />
        ) : (
          renderCardData()
        )}
      </div>
    </CardWrapper>
  );
};

export default CompanyCards;
